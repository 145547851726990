import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import { getSiblings } from './get-siblings.js';

export const tabs = () => {
  let swipers = [];

  //==  Start slider ==//
  if (document.querySelector(".tabs")) {
    const sliders = document.querySelectorAll('.tabSwiper');
    sliders.forEach((slider, index) => {
      swipers[index] = new Swiper(slider, {
        modules: [Autoplay],
        direction: "vertical",
        loop: true,
        spaceBetween: 8,
        speed: 10000,
        allowTouchMove: false,
        simulateTouch: false,
        keyboard: {
          enabled: false,
        },

        autoplay: {
          pauseOnMouseEnter: false,
          delay: 0,
          reverseDirection: true,
        },
        on: {
          init: (swiper) => { swiper.autoplay.start() }
        }
      });


      const sliderWrapper = slider.querySelector('.swiper-wrapper');
      let sliderItems = slider.querySelectorAll('.swiper-slide');
      let slidesCount = sliderItems.length;

      if (slidesCount <= 5) {
        let repeat = Math.ceil((8 / slidesCount) * 2);
        if (repeat > 0) {
          for (let i = 0, n = repeat; i < n; i++) {
            sliderItems.forEach((item) => {
              const swiperItemNew = document.createElement("div");
              swiperItemNew.classList.add("swiper-slide");
              swiperItemNew.innerHTML = item.innerHTML;

              sliderWrapper.append(swiperItemNew);
            });
          }
        }
      }
    });

    const tabsBodysWrapper = document.querySelectorAll('.tabSwiper-wrapper');
    const tabsBodys = document.querySelectorAll('.tabSwiper');
    const tabButtons = document.querySelectorAll('.tabs__button');
    let currentIndex = 0;
    let interval = null;

    tabButtons.forEach((tabButton, index) => {
      tabButton.addEventListener('click', () => {
        if (!tabButton.classList.contains('_tab-active')) {
          if (interval !== null) {
            clearInterval(interval)
          }
          tabButton.classList.add('_tab-active');


          getSiblings(tabButton).forEach(item => {
            item.classList.remove('_tab-active');
          });

          if (tabsBodysWrapper[index]) {
            tabsBodysWrapper[index].classList.add('active');
            getSiblings(tabsBodysWrapper[index]).forEach(item => {
              item.classList.remove('active');
            });
          }


          swipers.forEach((swiper, swiperIndex) => {

            if (index === swiperIndex) {
              swiper.autoplay.resume();
            }
            else {
              swiper.autoplay.pause();

            }
          })

          if (window.innerWidth <= 991) {
            if (interval !== null) {
              clearInterval(interval)
            }
          } else {
            interval = setInterval(() => {
              if (tabButtons[index + 1]) {
                tabButtons[index + 1].click()
              } else {
                tabButtons[0].click()
              }

            }, 10000);
          }


        }

      })
    })

    tabButtons[0].click()

    /////////////////////////

    const slidersMob = document.querySelectorAll('.buttonSwiper');
    slidersMob.forEach((sliderMob, index) => {
      const swiper = new Swiper(sliderMob, {
        modules: [Autoplay],
        direction: "vertical",
        loop: true,
        spaceBetween: 4,
        speed: 10000,
        allowTouchMove: false,
        simulateTouch: false,
        keyboard: {
          enabled: false,
        },
        autoplay: {
          pauseOnMouseEnter: false,
          delay: 0,
          reverseDirection: true,
        },
        on: {
          init: (swiper) => { swiper.autoplay.start() }
        }
      });


      const sliderWrapper = sliderMob.querySelector('.swiper-wrapper');
      let sliderItems = sliderMob.querySelectorAll('.swiper-slide');
      let slidesCount = sliderItems.length;

      if (slidesCount <= 5) {
        let repeat = Math.ceil((8 / slidesCount) * 2);
        if (repeat > 0) {
          for (let i = 0, n = repeat; i < n; i++) {
            sliderItems.forEach((item) => {
              const swiperItemNew = document.createElement("div");
              swiperItemNew.classList.add("swiper-slide");
              swiperItemNew.innerHTML = item.innerHTML;

              sliderWrapper.append(swiperItemNew);
            });
          }
        }
      }
    });


  }

};