export const post = () => {

    const content = document.querySelector('.post__content')
    if (content) {
        const titles = document.querySelectorAll('h3');
        const list = document.querySelector('.post__list');
        let count = 1;
        const sharp = '#';

        titles.forEach(title => {
            const listItem = document.createElement('a');
            listItem.textContent = title.textContent;
            listItem.href = sharp + count;
            title.id = count;
            list.appendChild(listItem);
            listItem.addEventListener('click', () => {
                const allItems = list.querySelectorAll('a');
                allItems.forEach((item) => item.classList.remove('active'));
                listItem.classList.add('active');
            })
            count++;
        })

        window.addEventListener('scroll', () => {
            const viewportMiddle = window.innerHeight / 2;
            const sectionTop = content.getBoundingClientRect().top;
            const header = document.querySelector('.header')
            const sectionHeight = content.offsetHeight;
            if (sectionTop <= viewportMiddle && sectionTop + sectionHeight > viewportMiddle) {
                header.classList.add('hidden')
            } else {
                header.classList.remove('hidden')
            }

        })

    }

}