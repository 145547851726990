import SimpleParallax from "simple-parallax-js/vanilla";
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const aboutSlider = () => {
    var images = document.querySelectorAll('.about__hero-image');
    var imagesPost = document.querySelectorAll('.post__image');
    
    new SimpleParallax((images, imagesPost), {
        scale: 1.4,
        transition: 'cubic-bezier(0,0,0,1)',
        delay: 3,
        customWrapper: '.image-wrap'
    });

    if (document.querySelector(".teamSwiper")) {
        const swiperTeam = new Swiper(".teamSwiper", {
            modules: [Navigation, Pagination],
            spaceBetween: 0,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
            },
            breakpoints: {

                700: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                },

                1010: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                }
            }
        });

        const slideButtons = document.querySelectorAll('.teamSwiper-button')

        slideButtons.forEach(button => {
            button.addEventListener('click', () => {
                button.classList.toggle('active')
                button.previousElementSibling.classList.toggle('hidden')
            })
        })
    }
}