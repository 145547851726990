import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";
import Swiper from 'swiper';
import {
    Autoplay,
    Navigation,
    Pagination,
    Keyboard,
    EffectFade,
    Scrollbar,
    EffectCoverflow,
    Controller,
    FreeMode,
    Grid
} from "swiper/modules";

export const components = async () => {
    gsap.registerPlugin(ScrollTrigger);
    Swiper.use([Autoplay, Navigation, Pagination, Keyboard, EffectFade, EffectCoverflow, Scrollbar, Controller, FreeMode, Grid]);

    const header = document.querySelector('#header');
    const componentsWrapper = document.querySelectorAll('.components');
    if (componentsWrapper.length > 0) {
        for (const section of componentsWrapper) {
            const elTop = section.querySelector('.components__top');
            const slider = section.querySelector('.components__items');
            const sliderItems = section.querySelectorAll('.components__item');
            const accordions = section.querySelectorAll('.components__block__content__accordion');
            const carousels = section.querySelectorAll('.components__block__content__logo-carousel');

            let mainSwiperProgress = 0;

            const setHeightEls = () => {
                section.style.setProperty('--el-top', `${elTop ? elTop.offsetHeight : 0}px`);
            }
            setHeightEls();

            const createLogoSlider = (carouselItem = null, carouselItemIndex = 0) => {
                if (carouselItem.swiper) carouselItem.swiper.destroy(true, true);

                if (carouselItem) {
                    new Swiper(carouselItem, {
                        slidesPerView: "auto",
                        spaceBetween: 8,
                        speed: 4000,
                        freeMode: true,
                        minimumVelocity: 0,
                        loop: true,
                        allowTouchMove: false,
                        simulateTouch: false,
                        keyboard: {
                            enabled: false,
                        },
                        autoplay: {
                            disableOnInteraction: false,
                            delay: 1,
                            reverseDirection: carouselItemIndex % 2 === 0,
                            pauseOnMouseEnter: false,
                        },
                    });
                }
            }

            let ltTimeout = null;

            const swiperMain = await new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 12,
                speed: 500,
                //freeMode: true,
                minimumVelocity: 0,
                allowTouchMove: false,
                simulateTouch: false,
                keyboard: {
                    enabled: false,
                },
                grid: {
                    rows: sliderItems.length,
                    fill: 'row'
                },
                breakpoints: {
                  768: {
                      slidesPerView: "auto",
                      grid: {
                          rows: 1,
                          fill: 'row'
                      },
                  }
                },
                on: {
                    init: (swiper) => {
                        if(window.matchMedia("(min-width: 768px)").matches && section.offsetHeight + 24 >= window.innerHeight && swiper.slides[0].offsetHeight + 24 <= window.innerHeight){
                            const tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: section,
                                    start: "bottom bottom-=12px",
                                    end: `${Math.ceil((swiper.virtualSize * 70) / window.innerHeight)}% bottom`,
                                    scrub: 1.5,
                                    pin: true,
                                    pinSpacing: true,
                                    //markers: true,
                                    onEnter: () => {
                                        header?.classList.add('move-up');
                                    },
                                    onEnterBack: () => {
                                        header?.classList.add('move-up');
                                    },
                                    onLeave: () => {
                                        header?.classList.remove('move-up');
                                    },
                                    onLeaveBack: () => {
                                        header?.classList.remove('move-up');
                                    },
                                },
                            });
                            const containerWidth = swiper.virtualSize;
                            tl.to(swiper.wrapperEl, {
                                delay: 0.015,
                                x: `-${containerWidth - window.innerWidth + 30}px`
                            });
                        } else {
                            swiper.allowTouchMove = swiper.simulateTouch = swiper.keyboard.enable = true;
                            swiper.update();
                        }

                        setTimeout(async () => {
                            if (carousels.length > 0) {
                                for (const carousel of carousels) {
                                    const carouselItems = carousel.querySelectorAll('.components__block__content__logo-carousel--item');
                                    if (carouselItems.length > 0) {
                                        let carouselItemIndex = 0;
                                        for (const carouselItem of carouselItems) {
                                            const sliderItems = carouselItem.querySelectorAll('.swiper-slide');
                                            const sliderWrapper = carouselItem.querySelector('.swiper-wrapper');
                                            let slidesCount = sliderItems.length;

                                            if (slidesCount <= 30) {
                                                let repeat = Math.ceil((30 / slidesCount) * 2);
                                                if (repeat > 0) {
                                                    for (let i = 0, n = repeat; i < n; i++) {
                                                        sliderItems.forEach((item) => {
                                                            const swiperItemNew = document.createElement("div");
                                                            swiperItemNew.classList.add('swiper-slide');
                                                            swiperItemNew.innerHTML = item.innerHTML;

                                                            sliderWrapper.append(swiperItemNew);
                                                        });
                                                    }
                                                }
                                            }
                                            createLogoSlider(carouselItem, carouselItemIndex);
                                            carouselItemIndex++;
                                        }
                                    }
                                }
                            }
                        }, 1000);
                    }
                }
            });

            if (accordions.length > 0) {
                accordions.forEach(accordion => {
                    const accordionItems = accordion.querySelectorAll('.components__block__content__accordion__item');
                    let activeIndex = null;

                    const disableSiblings = () => {
                        if (activeIndex !== null) {
                            accordionItems.forEach((item, itemIndex) => {
                                const trigger = item.querySelector('.components__block__content__accordion__title');
                                const content = item.querySelector('.components__block__content__accordion__desc');
                                if (trigger && content && activeIndex !== itemIndex) {
                                    trigger.classList.remove('active');
                                    $(content).slideUp(250);
                                }
                            });
                        }
                    }

                    accordionItems.forEach((item, itemIndex) => {
                        const trigger = item.querySelector('.components__block__content__accordion__title');
                        const content = item.querySelector('.components__block__content__accordion__desc');
                        const closest = item.closest('.components__block');
                        if (trigger && content) {
                            const animSpeed = 250;
                            trigger.addEventListener('click', () => {
                                let closestHeight = 0;
                                if (closest) closestHeight = closest.offsetHeight;

                                if (activeIndex === null) {
                                    activeIndex = itemIndex;
                                    trigger.classList.add('active');
                                    $(content).slideDown(animSpeed);
                                } else if (itemIndex === activeIndex) {
                                    activeIndex = null;
                                    trigger.classList.remove('active');
                                    $(content).slideUp(animSpeed);
                                } else {
                                    activeIndex = itemIndex;
                                    trigger.classList.add('active');
                                    $(content).slideDown(animSpeed);
                                    disableSiblings();
                                }
                                /*setTimeout(() => {
                                    if (swiperMain && swiperMain.el) swiperMain.update();

                                    if (closest && closestHeight !== closest.offsetHeight) {
                                        closestHeight = closest.offsetHeight;
                                        //ScrollTrigger.refresh();
                                    }
                                }, animSpeed);*/
                            });
                        }
                    });
                })
            }

            window.addEventListener('resize', () => {
                setHeightEls();
            })
        }
    }
}