import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const components = () => {
    gsap.registerPlugin(ScrollTrigger);
    const componentsWrapper = document.querySelectorAll('.components')
    if (componentsWrapper.length > 0) {

        componentsWrapper.forEach(component => {
            const slider = component.querySelector(".swiperComp")

            // window.addEventListener('scroll', () => {
            //     const viewportMiddle = window.innerHeight / 2;
            //     const section = component.querySelector('.swiperComp')
            //     const sectionTop = section.getBoundingClientRect().top;
            //     const header = document.querySelector('.header')
            //     const sectionHeight = section.offsetHeight;
            //     if (sectionTop <= viewportMiddle && sectionTop + sectionHeight > viewportMiddle) {
            //         header.classList.add('hidden')
            //     } else {
            //         header.classList.remove('hidden')
            //     }

            // })


            if (slider) {
                const swiperComponents = new Swiper(slider, {
                    spaceBetween: (window.innerHeight / 100) * 1.40845070423,
                    slidesPerView: "auto",
                    freeMode: true,
                    freeModeMomentum: false,
                    freeModeMomentumRatio: 0.02,
                    freeModeSticky: false,
                    on: {
                        init: (swiper) => {
                            const slides = swiper.slides;
                            let i = 0;
                            slides.forEach(slide => {
                                if (slide.classList.contains('full-width')) i++;
                            })
                            component.style.setProperty('--count', `${slides.length - i}`);


                            const tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: component,
                                    start: "top top",
                                    end: "bottom bottom",
                                    scrub: true,
                                    pin: true,
                                    pinSpacing: false,
                                    markers: true,
                                    onUpdate: (self) => {
                                        const progress = self.progress;
                                        console.log(progress);

                                        const totalSlides = swiper.slides.length;
                                        const slideIndex = Math.floor(progress * (totalSlides - 1));
                                        swiper.slideTo(slideIndex, 0);
                                        // const windowWidth = window.innerWidth - ((window.innerWidth / 100) * 0.83333) * 2;
                                        // const listWidth = list.offsetWidth;
                                        // const minusWidth = windowWidth * 100 / listWidth;
                                        // if (list) {
                                        //     list.style.transform = "translateX(" + (progress * (minusWidth - 100)) + "%)";
                                        // }
                                    }
                                },
                            });
                        },
                        resize: (swiper) => {
                            swiper.params.spaceBetween = (window.innerHeight / 100) * 1.40845070423;
                            swiper.update();
                        }
                    }
                })

            }
        })

    }



    //==  slider ==//
    const sliders = document.querySelectorAll(".componentsSlider1")

    if (sliders) {
        sliders.forEach(slider => {
            const swiperComponents = new Swiper(slider, {
                modules: [Autoplay],
                spaceBetween: 10,
                loop: true,
                centeredSlides: false,
                slidesPerView: "auto",
                speed: 8000,
                allowTouchMove: false,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false,
                },
            });

            const sliderWrapper = slider.querySelector('.swiper-wrapper')
            const sliderItems = slider.querySelectorAll('.swiper-slide')
            let slidesCount = sliderItems.length;

            if (slidesCount <= 8) {
                let repeat = Math.ceil((8 / slidesCount) * 2);
                if (repeat > 0) {
                    for (let i = 0, n = repeat; i < n; i++) {
                        sliderItems.forEach((item) => {
                            const swiperItemNew = document.createElement("div");
                            swiperItemNew.classList.add("swiper-slide");
                            swiperItemNew.innerHTML = item.innerHTML;

                            sliderWrapper.append(swiperItemNew);
                        });
                    }
                }
            }
        })

    }
    //==  slider ==//


    // componentsWrapper.forEach(component => {
    //     ///////////////
    //     window.addEventListener('scroll', () => {
    //         const viewportMiddle = window.innerHeight / 2;
    //         const section = component.querySelector('.components__blocks')
    //         const sectionTop = section.getBoundingClientRect().top;
    //         const header = document.querySelector('.header')
    //         const sectionHeight = section.offsetHeight;
    //         if (sectionTop <= viewportMiddle && sectionTop + sectionHeight > viewportMiddle) {
    //             header.classList.add('hidden')
    //         } else {
    //             header.classList.remove('hidden')
    //         }

    //     })
    //     //////////////
    //     const list = component.querySelector('.components__blocks')
    //     const listItem = list.querySelectorAll('.components__block')
    //     component.style.setProperty("--count", listItem.length)
    // const tl = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: component,
    //         start: "top top",
    //         end: "bottom bottom",
    //         scrub: 2,
    //         pin: true,
    //         pinSpacing: false,
    //         markers: true,
    //         onUpdate: (self) => {
    //             const progress = self.progress;
    //             const windowWidth = window.innerWidth - ((window.innerWidth / 100) * 0.83333) * 2;
    //             const listWidth = list.offsetWidth;
    //             const minusWidth = windowWidth * 100 / listWidth;
    //             if (list) {
    //                 list.style.transform = "translateX(" + (progress * (minusWidth - 100)) + "%)";
    //             }
    //         }
    //     },
    // });
    // })

}