import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const heroSlider = () => {

//==  Start hero-slider ==//
if (document.querySelector(".heroSwiper")) {
    const swiperHero = new Swiper(".heroSwiper", {
        modules: [Navigation, EffectFade, Autoplay],
        effect: "fade",
        spaceBetween: 0,
        virtualTranslate: true,
        speed: 1000, 
        loop: true,
        fadeEffect: { crossFade: true },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false
      },
    });
}

const video = document.querySelector('.hero__video')
const image = document.querySelector('.hero__image')

if (video) {
  let isVideoReadyInterval = setInterval(() => {
    if (video.readyState >= 1) {
      clearInterval(isVideoReadyInterval);
      image.style.opacity = '0';
      video.play();
    }
  }, 500);
}

//==  End hero-slider ==//

}