export const form = () => {

  //==  Start thanks ==//
  const contactForm = document.querySelectorAll('.form__main')

  contactForm?.forEach(item => {
    const thanksPop = item.querySelector('.form__thanks');
    const formWrapper = item.querySelector('.form__wrapper');
    const formTop = item.querySelector('.form__main-top');

    const form = formWrapper?.querySelector('form');
    form?.addEventListener('wpcf7mailsent', () => {
      thanksPop?.classList.add('active');
      formTop?.classList.add('hidden');
      formWrapper?.classList.add('hidden');

      //scroll to top block
      const header = document.querySelector('header')
      const headerHeight = header.getBoundingClientRect().height
      const scrollBlock = form.closest('div.form__main')
      const elementOffset = (scrollBlock?.getBoundingClientRect().top + window.scrollY) - headerHeight
      // smoothScrollTo(elementOffset,2)
      window.scrollTo({
        top: elementOffset,
        behavior: "smooth",
      });
    })

    //disabled button when form submitting

    form?.addEventListener('submit', function (event) {
      const button = form.querySelector('button[type="submit"]');
      button.disabled = true;
      //button.value = "Sending...";
    });

    document.addEventListener('wpcf7submit', function (event) {
      var buttons = document.querySelectorAll('.wpcf7-submit[disabled]');
      buttons.forEach(function (button) {
        button.disabled = false;
        //button.value = "Send message";
      });
    });

  })

  //==  End thanks ==//

  //==  start select ==//
  const selects = document.querySelectorAll('.custom-select');

  selects.forEach(select => {
    select.classList.add('hidden');
    const closest = select.closest('.wpcf7-form-control-wrap');
    const options = select.querySelectorAll('option');

    const div = document.createElement('div');
    div.classList.add('cs');

    const p = document.createElement('p');
    p.classList.add('cs_current');

    const ul = document.createElement('ul');
    ul.classList.add('another', 'hidden', 'cs_list');
    ul.style.display = 'none'

    options.forEach((option, index) => {
      const li = document.createElement('li');
      li.classList.add('another-f');
      li.innerHTML = option.textContent;

      if (index === 0) {
        p.innerHTML = option.textContent;
        // li.classList.add('disabled');
      }

      if (!li.classList.contains('disabled')) {
        li.addEventListener('click', () => {
          select.selectedIndex = index;

          p.innerHTML = li.textContent;
          $(ul).slideUp(300);
          ul.classList.remove('active');
          p.classList.remove('active');

          li.classList.add('active');
          $(li).siblings('li').removeClass('active')
        })
      }

      ul.append(li);
    });

    if (closest) {
      div.append(p);
      div.append(ul);
      closest.append(div);
    }

    p.addEventListener('click', () => {
      $(ul).slideToggle(300);
      ul.classList.toggle('active');
      p.classList.toggle('active');
    })
  })

  //==  end select ==//


  //==  start drag ==//
  const formWrapper = document.querySelector('.form__wrapper')
  const dragWrapper = document.querySelector('.codedropz-upload-wrapper')

  if (formWrapper && dragWrapper) {
    const dragTitle = dragWrapper.querySelector('h3')
    const ownTitle = document.querySelector('.upload-file-title')
    dragTitle.textContent = ownTitle.textContent
  }



  //==  end drag ==//

}